.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: normal;
  padding: 0 0px 180px 0px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.loadingContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;

  margin-top: 60px;
}
.spinner {
  position: relative;
  width: 77px;
  height: 77px;
  margin-bottom: 40px;
}

.title {
  font-size: 30px;
  font-weight: 300;
  color: var(--color-eggplant);
  margin-bottom: 12px;
}

.description {
  font-size: 14px;
  color: #000;
  margin-bottom: 24px;
  text-align: center;
  max-width: 320px;
}
.spinnerArc {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: -50%;
  animation: spin 1.5s linear infinite;
}
.calculatingText {
  color: #721cff;
  font-size: 16px;
  font-weight: 500;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} /* Animation classes */
.fadeOut {
  opacity: 0;
}

.fadeIn {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
