.button {
  border: none;
  color: white;
  padding: 10px 12px;
  text-align: center;
  text-decoration: none;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 8px;
  min-height: 48px;
}
.blackButton {
  background-color: #000;
}

.purpleButton {
  background-color: var(--color-purple1);
}

.discountButton {
  background-color: #000;
}

.clearButton {
  background-color: transparent;
  color: var(--color-gray1);
}
