.container {
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: none;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

/* Position variants */
.top-right {
  top: 0;
  right: 0;
  align-items: flex-end;
}

.top-left {
  top: 0;
  left: 0;
  align-items: flex-start;
}

.bottom-right {
  bottom: 0;
  right: 0;
  align-items: flex-end;
}

.bottom-left {
  bottom: 0;
  left: 0;
  align-items: flex-start;
}

.top-center {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
}

.bottom-center {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
}

.toast {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 12px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
  color: black;
  max-width: 400px;

  box-sizing: border-box;
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.icon {
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message {
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
}

/* Animation classes */
.entering {
  animation: bounceIn 0.3s ease forwards;
}

.exiting {
  animation: fadeOut 0.3s ease forwards;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
