.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: normal;
  padding: 0 0px 180px 0px;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.counter {
  color: var(--color-eggplant);
  font-size: 14px;
}

.title {
  font-size: 30px;
  font-weight: 300;
  color: var(--color-eggplant);
  margin-bottom: 12px;
  margin-top: 8px;
}

.description {
  font-size: 14px;
  color: var(--color-black);
  text-align: center;
  margin-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

.low {
  background-color: #f2f9f7;
  color: var(--color-moss);
}

.moderate {
  background-color: #fff3e6;
  color: #7f3f00;
}

.high {
  background-color: #fdedf1;
  color: #970021;
}
.earlyBird {
  background-color: #fff3e6;
  color: #7f3f00;
  margin-bottom: 30px;
}

.nightOwl {
  background-color: #f3efff;
  color: var(--color-purple1);
  margin-bottom: 30px;
}
.disruptors {
  background-color: #fff3e6;
}
.donutChart {
  width: 108px;
  height: 108px;
}
.scaleChart {
  width: 247px;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}
.resultsText {
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 12px;
}

.disruptorGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  gap: 24px 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.disruptorGridFewer {
  display: flex;
  justify-content: center;
}

.disruptorIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 54px;
  width: 90px;
  opacity: 0;
  /* transform: scale(0.5); */
}

.disruptorIconVisible {
  animation: bounceIn 0.6s ease forwards;
}

.planGraphic {
  width: 100%;
  height: auto;
}

.isiHealthyCard {
  background-color: #e8effb;
  color: #003c9e;
  margin-bottom: 30px;
}
.isiAtRiskCard {
  background-color: #fff3e6;
  color: #7f3f00;
  margin-bottom: 30px;
}
.isiPoorCard {
  background-color: #fff3e6;
  color: #7f3f00;
  margin-bottom: 30px;
}
.isiCriticalCard {
  background-color: #fdedf1;
  color: #970021;
  margin-bottom: 30px;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.bold {
  font-weight: bold;
}

/* Animation classes */
.fadeOut {
  opacity: 0;
}

.fadeIn {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
