.container {
  border-radius: 16px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 20px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 8px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
  margin: 20px 0;
  opacity: 0.1;
}

.badge {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.bannerText {
  font-size: 12px;
  color: var(--color-black);
}
