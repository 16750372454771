.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

.modalContainer {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  animation: zoomIn 0.3s ease;
}

.modalHeader {
  padding: 20px 20px;
}

.modalTitle {
  margin: 0;
  font-size: var(--font-size-display1);
  font-weight: 400;
  color: var(--color-black);
  text-align: center;
}

.modalBody {
  padding: 0px 20px 20px 20px;
  font-size: var(--font-size-heading4);
  font-weight: 400;
  text-align: center;
}

.modalFooter {
  padding: 10px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 12px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
