.container {
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: visible;
  cursor: pointer;
  background: var(--color-lavender);
  position: relative;
  padding: 20px 20px;
  margin-left: 16px;
  margin-right: 16px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 28px;
  color: var(--color-purple1);
}

.price {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.priceContainer {
  display: flex;
  font-size: 16px;
  color: var(--color-eggplant);
  padding-top: 6px;
  gap: 4px;
  justify-content: center;
  text-align: center;
}
