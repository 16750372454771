.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner {
  color: #fff;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
  background-image: url('./background.svg');
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
}
.image {
  width: 16px;
  margin-right: 6px;
}
