.container {
  padding-left: 16px;
  padding-right: 16px;
}

/* Survey Theme */
.rise-root {
  background-color: white;
}
.rise-title .rise-action-bar {
  display: none;
}
.sd-body__navigation.rise-action-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: initial;
  gap: 0;
  padding-bottom: 0;
  padding-top: 0;
  padding-bottom: 16px;
}
.rise-action {
  background-color: orange;
}
.sd-question__header {
  padding-bottom: 32px;
}
.rise-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rise-title {
  font-size: 21px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: black;
  margin-top: 0;
  margin-bottom: 0px;
}
.sd-description {
  font-size: 16px;
  margin-top: 6px !important;
  color: #4f4f4f;
  font-weight: 500;
}

.sd-selectbase__item.sd-item--checked {
  background-color: black;
  color: white;
}
.sd-item--checked .sd-item__decorator {
  background: none;
}
.sd-selectbase__item {
  border-radius: 15px;
  border: 1px solid #000000;
  padding-top: 0;
  padding-bottom: 0;
  user-select: none;
}

.rise-radio-new-style .sd-selectbase__item.sd-item--checked {
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  color: black;
  transform: scale(1);
}
.rise-radio-new-style .sd-selectbase__item:active {
  transform: scale(0.96);
}
.rise-radio-new-style .sd-selectbase__item {
  border-radius: 12px;
  border: 1px solid #bdbdbd;
  padding-top: 0;
  padding-bottom: 0;
  transition-property: transform, border-color, background-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.sd-selectbase {
  gap: 12px;
  display: flex;
  flex-direction: column;
}
.sd-imagepicker {
  flex-direction: row;
}
.sd-item__decorator {
  display: none;
  position: absolute;
  right: 16px;
  width: 18px;
  height: 18px;
}

.rise-radio-new-style .sd-item--checked .sd-radio__decorator {
  display: block;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../images/check-mark-circle.svg');
}

.rise-radio-new-style .sd-radio--checked .sd-radio__decorator:after {
  display: none;
}

.rise-radio-new-style .sd-item--checked .sd-item__control-label {
  color: black;
}
.sd-selectbase__label {
  display: flex;
  flex: 1;

  padding-top: 15px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
}

.sd-item__control-label {
  color: black;
  font-size: 16px;
  line-height: 1.2;
}

.sd-item--checked .sd-item__control-label {
  color: white;
}

.sd-page {
  opacity: 1;
  transition: opacity 4.5s ease-in-out;
}
.sd-root-modern--mobile .sd-page {
  padding-bottom: 100px;
}
.sd-page.fade-out {
  opacity: 0;
}

.sd-page.fade-in {
  opacity: 1;
}
.sd-input.sd-text {
  background-color: white;
  border-radius: 12px;
  border: 1px solid #4f4f4f;
  box-shadow: none;
}
.sd-input.sd-text:focus {
  border: 1px solid #721cff;
}

.survey-page-transition {
  transition:
    opacity 200ms ease-in-out,
    transform 200ms ease-in-out;
}

.survey-page-transition.fade-out {
  transition-delay: 200ms;
  opacity: 0;
}

.survey-page-transition.fade-in {
  transition-delay: 0ms;
  opacity: 1;
}
/* NOTE: There were cases when this would appear when we don't need it with the autoadvance work.  */
.sd-question__erbox--above-question {
  display: none !important;
}

/* Image Picker */
.sd-imagepicker__check-decorator {
  display: none;
}
/* Image Picker  */
.sd-imagepicker__item img {
  border-radius: 0;
  background-color: transparent;
  height: auto;
}

.sd-imagepicker {
  gap: 15px;
}
.sd-imagepicker__item {
  border-radius: 12px;
  background-color: white;
  border: 1px solid rgba(142, 88, 255, 0.2);
  overflow: hidden;
  flex: 1;
  min-width: 163px;
}

.sd-imagepicker__image-container {
  height: 120px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}
.sd-imagepicker__item--checked .sd-imagepicker__image-container {
  background-color: #f2f2f2;
}

.sd-imagepicker__text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin-top: 0;
  height: 40px;
  background-color: rgba(142, 88, 255, 0.2);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

/* Checkbox styling to match radio buttons */
.sd-item.sd-checkbox.sd-selectbase__item {
  padding-right: 10px;
}
.sd-item.sd-checkbox.sd-selectbase__item.sd-item--checked {
  background: var(--color-gray5);
  position: 'relative';
  border-color: transparent !important;
  overflow: 'hidden';
  -webkit-tap-highlight-color: transparent;
}
.sd-item.sd-checkbox.sd-selectbase__item.sd-item--checked::before {
  content: '';
  width: 18px;
  height: 18px;
  background-image: url('../../images/black-checkmark.svg');
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  right: 12px;
  margin-top: 16px;
  z-index: 1000;
}
.sd-item.sd-checkbox.sd-selectbase__item.sd-item--checked .sd-item__control-label {
  color: black;
}

.sd-selectbase__item,
.sd-imagepicker__item {
  -webkit-tap-highlight-color: transparent;
}
