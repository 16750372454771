.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;
  padding-bottom: 30px;
}
.scale {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.bar {
  min-width: 250px;
  height: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  border-radius: 3px;
  position: relative;
}
.segment {
  width: 100%;
  height: 100%;
  position: relative;
}

.segment1 {
  background: linear-gradient(to right, #4a1cff, #985891);
}
.segment2 {
  background: linear-gradient(to right, #985891, #fe9603);
}
.segment3 {
  background: linear-gradient(to right, #fe9603, #f25722);
}
.segment4 {
  background: linear-gradient(to right, #f25722, #e52243);
}

.scaleEndLabel {
  font-size: 12px;
  color: #000;
  font-weight: 600;
}

.scaleLabel {
  position: absolute;
  font-size: 12px;
  color: #000;
  flex: 1;
  font-weight: 400;
  text-align: center;
  opacity: 0.65;
  bottom: -20px;
  width: 100%;
  text-align: center;
}
.pin {
  position: absolute;
  top: -40px;
  left: 0;
  width: 27px;
  transition: left 0.5s ease-in-out;
}
.pinLabel {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  position: absolute;
  width: 100%;
  top: 7px;
}
