.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.item {
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: visible;
  cursor: pointer;
  background: rgba(232, 222, 255, 0.5);
  /* transition: all 0.3s ease; */
  position: relative;
  padding: 12px 20px;
  margin-left: 16px;
  margin-right: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 21px;
  color: var(--color-black);
}

.price {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.content {
  position: relative;
  /* height: 0; */
  overflow: hidden;
  /* transition: height 0.3s ease-in-out; */
}

.contentInner {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease; */
}

.description {
  font-size: 14px;
  line-height: 140%;
  color: var(--color-gray1);
  padding-top: 0px;
  padding-right: 32px;
}

.item.open {
  border-color: #721cff;
  background: rgba(232, 222, 255);
}

.item.open .content {
  /* height: var(--content-height); */
}

.item.open .contentInner {
  opacity: 1;
  transform: translateY(0);
}
.item.open .badge {
  background: #721cff;
}
.badge {
  position: absolute;
  top: -12px;
  right: 12px;
  background: rgb(175, 125, 255);
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item.open .subtitle {
  color: #721cff;
}
.subtitle {
  font-size: 12px;
  color: var(--color-black);
  font-weight: 600;
  margin-bottom: 4px;
}
.perDayContainer {
  /* position: absolute;
  top: 0;
  right: 20px; */
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2px;
  margin-top: 12px;
}
.currency {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 700;
  line-height: 1;
  margin-top: 3px;
}
.dollars {
  font-size: 40px;
  font-weight: 500;
  color: var(--color-black);
  line-height: 1;
}
.cents {
  font-size: 16px;
  color: var(--color-black);
  font-weight: 700;
  line-height: 1;
  margin-top: 3px;
}
.perDayLabel {
  margin-top: 2px;
  font-size: 10px;
  color: var(--color-gray3);
}
