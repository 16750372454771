.container {
  margin: 0 auto;
  background: #fff;
  font-family: Arial, sans-serif;
  padding: 12px;
  padding-top: 0px;
  position: relative;
  padding-bottom: 10rem;
}

.savedDiscountBannerContainer {
  position: fixed;
  top: -100px;
  left: 0;
  right: 0;
  transition: top 0.3s ease-in-out;
}

.savedDiscountBannerContainer.show {
  top: 0;
}

.savedDiscountBannerContainer.hide {
  top: -100px;
}

.title {
  font-size: 38px;
  font-weight: 900;
  line-height: 1.1;
  text-align: center;
  color: var(--color-eggplant);
  margin-bottom: 16px;
  margin-top: 20px;
}
