.container {
  border-radius: 11px;
  padding: 18px;
  padding-top: 20px;
  padding-bottom: 16px;

  display: flex;
  flex-direction: row;
  background: rgba(232, 222, 255, 0.5);
  gap: 14px;
  align-items: flex-start;
}

.selected {
  border: 2px solid var(--color-purple1);
}

.strikeThroughPrice {
  color: var(--color-gray3);
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 500;
}
.price {
  color: var(--color-purple1);
  font-weight: 800;
  font-size: 14px;
}
.productContainer {
  flex: 1;
}
.title {
  font-size: var(--font-heading3);
  font-weight: 800;
  color: var(--color-black);
  margin-bottom: 8px;
  margin-top: 6px;
}
.description {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray1);
  margin-bottom: 4px;
}
.priceContainer {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--color-gray3);
  margin-top: 12px;
}
.perDayContainer {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 2px;
  margin-top: 8px;
}

.image {
  width: 68px;
  object-fit: contain;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.2));
}
.discount {
  background-color: #dccdff;
  color: var(--color-purple1);
  padding: 4px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
}
