.container {
  display: flex;
  flex-direction: column;

  position: relative;
}
.finePrint {
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 12px;
  color: var(--color-gray2);
  transition: transform 0.5s ease;
}
.strikeoutPrice {
  text-decoration: line-through;
  color: var(--color-gray3);
}
.cta {
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: transform 0.5s ease;
}

/* Animation classes */
.fadeContainer {
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
  overflow: hidden;
}

.singlePlanFadeOut {
  animation: fadeUpOut 0.5s ease forwards;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.planOptionsFadeIn {
  animation: fadeDownIn 0.5s ease forwards;
  opacity: 0;
  width: 100%;
}

@keyframes fadeUpOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeDownIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
