.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 40px;
}

.buttonContainer {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}

.title {
  font-size: var(--font-size-display1);
  font-weight: 800;
  margin-top: 48px;
  margin-bottom: 12px;
}

.subtitle {
  font-size: var(--font-size-heading6);
  font-weight: 500;
}

.description {
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray1);
}
